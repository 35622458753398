<template>
  <div>
    <h1 class="mb-4">Реферальная программа</h1>
    <div class="d-flex flex-column-reverse flex-xxl-row">
      <div class="hovering__pad p-4 referal__block mr-xxl-3">
        <h2>
          Получайте дни подписки и проверки блогеров бесплатно. Приглашайте подписчиков пользоваться
          сервисами easyprbot.com
        </h2>
        <div class="mt-4 w-100 pb-5" style="border-bottom: 1px solid #d5dfe4">
          <div class="referal__step referal__step-one">
            <h5>Скопируйте ссылку</h5>
            <b-form class="mt-3" inline
              ><b-form-input
                v-model="codeLink"
                style="min-width: 200px"
                class="mr-3 h-36"
              ></b-form-input
              ><b-button
                v-if="code"
                v-clipboard:copy="codeLink"
                class="px-4 h-36 mt-3 mt-sm-0"
                variant="yellow"
                >Скопировать</b-button
              ></b-form
            >
          </div>
        </div>
        <div class="mt-4 pb-5" style="border-bottom: 1px solid #d5dfe4">
          <div class="referal__step referal__step-two">
            <h5>
              Разместите ссылку в сторис по свайпу, в шапке профиля, телеграм канале или другим
              способом. <br />
              <br />
              Вы можете использовать следующие рекламные материалы:
            </h5>
            <b-button class="mr-3 h-36 px-4 mt-4" variant="primary">Рекламная подача</b-button>
            <b-button class="h-36 px-4 mt-4" variant="outline-default">Тезисы</b-button>
          </div>
        </div>
        <div class="mt-4 pb-5">
          <div class="referal__step referal__step-three">
            <h5>
              Отслеживайте полученные дни подписки и начисленные проверки блогеров в таблице ниже.
              <br />
              <br />
              За каждые 30 дней подписки, приобретенные приглашенным пользователем, вам будет
              начисляться 10 дней подписки и 3 проверки блогеров, а за покупку проверок по ссылке
              другим пользователем вы будете получать 20% от приобретенного количества.
            </h5>
          </div>
        </div>
      </div>
      <div class="hovering__pad referal__block p-4 ml-xxl-4 mt-4 mt-xxl-0 mb-4 mb-xxl-0">
        <div>
          <img
            class="mr-3 d-inline-block"
            style="width: 35px; height: 23px"
            src="@main/assets/img/icons/ut_icon.png"
          />
          <span style="font-size: 16px">Как работает реферальная программа?</span>
        </div>
        <div class="mt-4 vid-checker">
          <b-embed
            type="iframe"
            aspect="16by9"
            src="https://www.youtube.com/embed/E353vqPp6L8"
            allowfullscreen
          ></b-embed>
        </div>
      </div>
    </div>
    <div class="hovering__pad mt-4 py-4">
      <h1 class="mb-4 mt-1 ml-4">Статистика начислений</h1>
      <b-table
        v-if="!isMobileDevice"
        :fields="fields"
        :items="prizes"
        striped
        bordered
        responsive
        stacked="md"
        ><template #cell(energy)="data">
          <div class="d-flex align-items-center">
            <p>{{ data.value }}</p>
            <img class="ml-1" src="@main/assets/img/svg/energy.svg" alt="" />
          </div>
        </template>
        <template #empty> <h4>В статистике пока нет записей.</h4></template></b-table
      >
      <div v-else-if="prizes && !!prizes.length">
        <DefaultMobileTable
          class="mobile-table"
          stripped
          bordered
          :item="item"
          :fields="fields"
          v-for="item in prizes"
          :key="item.key"
          ><template #cell(created_at)-custom="data">
            <p class="text-right fs-12 gray-text mb-1">Дата: {{ data.value }}</p></template
          ><template #cell(energy)="data"
            ><div class="d-flex align-items-center">
              <p>{{ data.value }}</p>
              <img class="ml-1" src="@main/assets/img/svg/energy.svg" alt="" /></div></template
        ></DefaultMobileTable>
      </div>
      <div class="hovering__pad" v-else><h4>В статистике пока нет записей.</h4></div>
    </div>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import DefaultMobileTable from '@main/components/reusable/tables/DefaultMobileTable.vue';
import literal from '@main/func/literal';
import moment from 'moment';
import referal from '@main/api/referal';

export default {
  components: {
    DefaultMobileTable,
  },
  data: () => ({
    prizes: null,
    code: null,
  }),
  computed: {
    fields() {
      return [
        {
          key: 'created_at',
          label: 'Дата',
          thClass: 'fw-500',
          class: 'px-4 py-3',
          formatter(value, key, item) {
            return moment(value).format('DD.MM.YYYY');
          },
          type: 'other',
        },
        {
          key: 'code',
          label: '№ пользователя',
          thClass: 'fw-500',
          class: 'px-4 py-3',
        },
        {
          key: 'energy_price',
          label: 'Пользователь купил',
          formatter(value, key, item) {
            if (item.subscription_price) {
              return `${item.subscription_price.title} подписки`;
            }
            if (item.energy_price) {
              return `${item.energy_price.quantity} проверок`;
            }
          },
          thClass: 'fw-500',
          class: 'px-4 py-3',
        },
        {
          key: 'subscription_days',
          label: this.isMobile ? 'Начислено дней' : 'Вам начислено дней подписки',
          thClass: 'fw-500',
          class: 'px-4 py-3',
        },
        {
          key: 'energy',
          label: this.isMobile ? 'Начислено проверок' : 'Вам начислено проверок',
          thClass: 'fw-500',
          class: 'px-4 py-3',
        },
      ];
    },
    isMobileDevice() {
      return isMobile;
    },
    codeLink() {
      return `https://easyprbot.com/#/referal_${this.code}`;
    },
  },
  methods: {
    async updateData() {
      try {
        const response = await referal.referalPrize.list();
        this.prizes = response.results;
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    await this.updateData();
    const code = await referal.code.my();
    this.code = code.code;
    console.log(this.prizes);
  },
};
</script>

<style lang="scss" scoped>
.referal__step {
  position: relative;
  padding-left: 48px;
  padding-top: 6px;
}
.referal__step-one::before {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #3897f0;
  content: '1';
  left: 0;
  top: 0;
  width: 32px;
  height: 32px;
}
.referal__step-two::before {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #3897f0;
  content: '2';
  left: 0;
  top: 0;
  width: 32px;
  height: 32px;
}
.referal__step-three::before {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #3897f0;
  content: '1';
  left: 0;
  top: 0;
  width: 32px;
  height: 32px;
}
@media (min-width: 1350px) {
  .referal__block {
    width: 50%;
  }
}
::v-deep .mobile-table td {
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}
::v-deep .mobile-table th {
  color: #6a7b83;
}
</style>
